import React, { useState, useEffect } from 'react';
import './Header.css';
import { motion } from 'framer-motion';

function Header() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowBanner(true);
        window.removeEventListener('scroll', handleScroll);
        clearTimeout(timer);
      }
    };

    const timer = setTimeout(() => {
      setShowBanner(true);
      window.removeEventListener('scroll', handleScroll);
    }, 5000); // Affiche la bannière après 5 secondes si l'utilisateur n'a pas défilé

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      {showBanner && (
        <motion.header
          className="banner"
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="banner-content">
            <div className="left-content">
              <h2 style={{ fontFamily: 'var(--romantic-font)' }} onClick={()=>window.scrollTo(0,0)} >S&G</h2>
            </div>
            <div className="right-content">
              <div className="box" onClick={() => scrollToSection('programme')}>Programme</div>
              <div className="box" onClick={() => scrollToSection('liste')}>Liste</div>
              <div className="box" onClick={() => scrollToSection('faq')}>FAQ</div>
            </div>
          </div>
        </motion.header>
      )}
    </div>
  );
}

export default Header;